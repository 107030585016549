.header {
  position: fixed;
  top: 0;
  z-index: 6;
  width: 100%;
}

.header .header__navbar {
  background-color: var(--color-primary);
}

.header__navbar .nav-wrapper {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}
/* logo ml & client */
nav .logo__wrapper {
  display: flex;
  align-items: center;
  height: inherit;
  padding-left: 1.5rem;
  margin-right: 10px;
  cursor: pointer;
}

.logo__wrapper .ml-logo {
  display: flex;
  margin-right: 10px;
  width: 100px;
  overflow: hidden;
}

.logo__wrapper .ml-logo img {
  height: 50px;
  object-fit: cover;
  object-position: left;
}

.logo__wrapper .client-logo {
  display: flex;
  align-items: center;
  height: 100%;
  width: fit-content;
}

.client-logo .absolute-logo {
  filter: brightness(0) invert(1);
}

.logo__wrapper .client-logo .client-logo-img {
  max-width: 165px;
}

.header nav ul a {
  padding: 0 10px;
}

.header-hub__disabled {
  color: var(--color-grey-disabled);
  padding-left: 1rem;
  padding-right: 1rem;
}

.header-link i.left,
.header-btn i.left,
.header-hub__plate i.left {
  margin-right: 9px;
}

.header-hub__plate {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.header-btn {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  box-shadow: none;
}

.user-btn {
  text-transform: none;
}

.header-link.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.header-link.complaints {
  position: relative;
}

/* popover */

.popover-content.header-popover-content {
  z-index: 15;
  height: 370px;
  border: none;
  border-radius: 12px;
  outline: none;

  max-width: 250px;
  min-width: 250px;
}

.header-popover-content .searchable-select__menu {
  box-shadow: none;
  width: calc(100% - 0.9rem);
}

@media screen and (min-width: 1520px) {
  .logo__wrapper .ml-logo {
    width: fit-content;
  }

  .logo__wrapper .ml-logo img {
    object-fit: contain;
  }
}
