.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form .input-field {
  width: 80%;
}

.login-btn {
  width: fit-content;
}
