:root {
  /* colors */
  --color-primary-white: #fff;
  --bg: #f4faff;
  --color-grey-light: #e0e3e7;
  --color-bg-overlay: rgba(255, 255, 255, 0.5);
  --color-row-border: rgba(0, 0, 0, 0.12);
  --color-border-grey: #9e9e9e;
  --color-grey: #cfd3d8e8;
  --color-grey-text: #757575;
  --color-grey-disabled: #9d9c9c;
  --color-grey-light: #e6e9ece8;
  --color-grey-light: rgba(0, 0, 0, 0.08);
  --color-black: #0c0c0c;
  --color-primary: #3f51b5;
  --color-primary-hover: rgb(68 102 200);
  --color-primary-focus: rgb(54 61 162);
  --color-primary-light: #94a8e2;
  --color-primary-lighten-5: #e8eaf6;
  --color-primary-alpha: rgba(148, 168, 226, 0.15);
  --color-primary-green: #26a69a;
  --color-pink-light-bg: #f8bbd0;
  --color-error-bg: #fde2e2;
  --color-red: rgb(211, 47, 47);
  --color-red-hover: rgb(216 68 66);
  --color-red-focus: rgb(202 6 9);
  --color-usual-red: red;
  --color-orange: #ff8f00;
  --bg-row-is-checked: #f5f5f5;
  --bg-row-is-defect: #fce4ec;
  --bg-row-is-accent-defect: #f7a6a6;
  --bg-row-no-defect: #e1f5fe;
  --bg-row-incorrect-summ: #f1f8e9;
  --bg-row-is-historical: #eceff1;
  --bg-row-is-error: #fff9c4;

  /* effects */
  --box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
  --box-shadow-antd: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  --box-shadow-light: 0 2px 7px 0 rgb(0 0 0 / 9%),
    0 2px 1px -2px rgb(0 0 0 / 9%), 0 1px 5px 0 rgb(0 0 0 / 13%);
  --box-shadow-hover: 0px 10px 20px -8px rgba(0, 0, 0, 0.7);
  --button-box-shadow-hover: 0 3px 3px 0 rgba(0, 0, 0, 0.14),
    0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
  --text-shadow: 1px 0px 1px #cccccc, 0px 1px 1px #eeeeee, 2px 1px 1px #cccccc,
    1px 2px 1px #eeeeee, 3px 2px 1px #cccccc, 2px 3px 1px #eeeeee,
    4px 3px 1px #cccccc, 3px 4px 1px #eeeeee, 5px 4px 1px #cccccc,
    4px 5px 1px #eeeeee, 6px 5px 1px #cccccc, 5px 6px 1px #eeeeee,
    7px 6px 1px #cccccc;
  /* font */

  --title-fs: 1.6rem;
  --medium-fs: 1.3rem;
  --font-weight-b: 500;
  --font-weight-mb: 700;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  width: 100%;
  min-height: 100vh;
  background: var(--bg);
  overflow: auto;
}

#root {
  width: 100%;
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

blockquote {
  border-left: 5px solid var(--color-primary);
}

blockquote i {
  color: var(--color-red);
}

.Toastify__toast-body {
  white-space: pre-wrap;
}

.Toastify__toast-container--top-left {
  top: 70px !important;
}

.Toastify__toast-body ul,
.Toastify__toast-body p {
  margin: 0;
}

.Toastify__toast-body ul {
  padding-left: 12px;
}

.Toastify__toast-body ul li {
  list-style-type: disc;
}

.dropdown-content {
  z-index: 4;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip {
  z-index: 999;
  max-width: 100%;
  background-color: var(--color-black);
}

.tooltip ul {
  margin: 0;
}

/* buttons */
.btn,
.btn-large,
.btn-small {
  background-color: var(--color-primary);
}

.btn:hover,
.btn-large:hover,
.btn-small:hover {
  background-color: var(--color-primary-hover);
}

.btn:focus,
.btn-large:focus,
.btn-small:focus {
  background-color: var(--color-primary-focus);
}

.approved-mark {
  cursor: auto;
}

button.btn-white-border {
  border: 2px solid var(--color-primary-white);
}

button.btn-white-border.btn-floating.btn-large {
  width: 58px;
  height: 58px;
}

button.btn-white-border.btn-floating.btn-large i {
  width: 56px;
}

/* input */
input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]),
input.valid:not([type]),
input.valid:not([type]):focus,
input.valid[type='text']:not(.browser-default),
input.valid[type='text']:not(.browser-default):focus,
input.valid[type='password']:not(.browser-default),
input.valid[type='password']:not(.browser-default):focus,
input.valid[type='email']:not(.browser-default),
input.valid[type='email']:not(.browser-default):focus,
input.valid[type='url']:not(.browser-default),
input.valid[type='url']:not(.browser-default):focus,
input.valid[type='time']:not(.browser-default),
input.valid[type='time']:not(.browser-default):focus,
input.valid[type='date']:not(.browser-default),
input.valid[type='date']:not(.browser-default):focus,
input.valid[type='datetime']:not(.browser-default),
input.valid[type='datetime']:not(.browser-default):focus,
input.valid[type='datetime-local']:not(.browser-default),
input.valid[type='datetime-local']:not(.browser-default):focus,
input.valid[type='tel']:not(.browser-default),
input.valid[type='tel']:not(.browser-default):focus,
input.valid[type='number']:not(.browser-default),
input.valid[type='number']:not(.browser-default):focus,
input.valid[type='search']:not(.browser-default),
input.valid[type='search']:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid var(--color-primary);
  box-shadow: 0 1px 0 0 var(--color-primary);
}

.switch label input[type='checkbox']:checked + .lever {
  background-color: var(--color-primary-light);
  /* vertical-align: baseline; */
}

.switch label input[type='checkbox']:checked + .lever:after,
input[type='checkbox'].filled-in:checked + span:not(.lever):after {
  background-color: var(--color-primary);
}

input[type='checkbox'].filled-in:checked + span:not(.lever):after {
  border: 2px solid var(--color-primary);
}

.switch label {
  font-weight: 400;
  font-size: inherit;
  color: inherit;
}

input[type='checkbox']:checked:not(:disabled) ~ .lever:active::before {
  background-color: var(--color-primary-alpha);
}

input:not([type]):focus:not([readonly]) + label,
input[type='text']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='url']:not(.browser-default):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly])
  + label,
input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
input[type='number']:not(.browser-default):focus:not([readonly]) + label,
input[type='search']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: var(--color-primary);
}

input[type='file']::-webkit-file-upload-button {
  display: none;
}

/* checkbox */

[type='checkbox']:checked + span:not(.lever):before {
  border-right: 2px solid var(--color-primary);
  border-bottom: 2px solid var(--color-primary);
}

/*react-materialize select */
.input-field .select-wrapper ul.dropdown-content {
  top: calc(100% - 6rem - 8px);
}

.input-field .select-wrapper ul.dropdown-content li {
  min-height: 0;
}

.input-field .select-wrapper ul.dropdown-content li > span {
  padding: 8px 12px;
  font-size: inherit;
  line-height: 1.5rem;
  color: var(--color-primary);
}

.select-wrapper input.select-dropdown:focus {
  border-bottom: 1px solid var(--color-primary);
}

/* react-materialize collapsible */
.keyboard-focused .collapsible-header:focus {
  background-color: transparent;
}

/* popover */

.popover-content {
  background-color: var(--color-primary-white);
  width: var(--radix-popover-trigger-width);
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  z-index: 4;
}
/* custom scrollbar */
.popover-content .searchable-select__menu-list::-webkit-scrollbar {
  width: 8px;
}

.popover-content .searchable-select__menu-list::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}
.popover-content .searchable-select__menu-list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: var(--color-primary-light);
}

.popover-content .searchable-select__menu-list::-webkit-scrollbar-thumb:active {
  background: var(--color-primary);
}
.filter-label {
  text-wrap: nowrap;
  font-size: 0.8rem;
  margin-left: 10px;
}
