.searchable-select {
  width: 100%;
  padding: 0 0.5rem;
  margin-bottom: 8px;
}

.select-defect-wrapper {
  display: flex;
  justify-content: center;
}

.select-defect-wrapper .searchable-select {
  padding: 0;
}

.searchable-select__filters-wrapper {
  width: 25%;
  height: 54px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.searchable-select .searchable-select__control {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0px;
  border-color: var(--color-border-grey);
  background-color: transparent;
  height: auto;
  margin-bottom: 0.5rem;

  cursor: pointer;
}

.searchable-select__control::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: -12px;
  left: 0;

  font-size: 0.8rem;
  color: var(--color-border-grey);
}

/* Crunch for custom select Label */
#UserSelect-1 .searchable-select__control::before {
  content: 'Группы';
}
#UserSelect-2 .searchable-select__control::before {
  content: 'Заказчик*';
}

#SysRulesSelect-3 .searchable-select__control::before {
  content: 'Формулировка в актах МЭ';
}

#SpSysSelect-1 .searchable-select__control::before {
  content: 'Правило*';
}
#EditRuleSelect-1 .searchable-select__control::before,
#DefectSelect-1 .searchable-select__control::before {
  content: 'Дефект';
}
#EditRuleSelect-4 .searchable-select__control::before {
  content: 'Код ЛПУ';
}

#EditRuleSelect-6 .searchable-select__control::before {
  content: 'Города';
}

#EditRuleSelect-7 .searchable-select__control::before {
  content: 'Типы кейсов';
}

#SysRulesSelect-4 .searchable-select__control::before {
  content: 'Комментарий';
}

#SysRulesSelect-2 .searchable-select__control::before {
  content: 'Раздел правила';
}

#AddTaskSelect-1 .searchable-select__control::before {
  content: 'Исполнитель (в список попадают пользователи с заполненным email и готовые получать уведомления)';
}

#MassDefectSelect-1 .searchable-select__control::before {
  content: 'Причина отклонения';
}

/*  */
.searchable-select .searchable-select__control:hover {
  border-color: var(--color-border-grey);
}

.searchable-select .searchable-select__control:focus,
.searchable-select .searchable-select__control--is-focused,
.searchable-select .searchable-select__control--menu-is-open,
.searchable-select .searchable-select__control--is-focused:hover,
.searchable-select .searchable-select__control--menu-is-open:hover {
  border-color: transparent;
  border-bottom: 1px solid var(--color-primary);
  box-shadow: 0 1px 0 0 var(--color-primary);
}

.searchable-select .searchable-select__control:focus::before,
.searchable-select .searchable-select__control--is-focused::before,
.searchable-select .searchable-select__control--menu-is-open::before {
  color: var(--color-primary);
}

.searchable-select .searchable-select__value-container {
  padding: 0;
}

.searchable-select .searchable-select__placeholder {
  color: var(--color-border-grey);
}

.searchable-select .searchable-select__placeholder,
.searchable-select .searchable-select__input-container,
.searchable-select .searchable-select__single-value {
  margin-left: 0;
}

.searchable-select .searchable-select__input {
  border: none;
}

.searchable-select .searchable-select__input-container {
  height: 46px;
  border-bottom: none;
  margin-bottom: 4px;
}

.searchable-select .searchable-select__input:focus {
  box-shadow: none !important;
}

.searchable-select .searchable-select__indicator-separator {
  display: none;
}

.custom-caret.searchable-select__dropdown-indicator {
  padding: 8px 0;
}

.searchable-select .searchable-select__menu {
  margin-top: 0;
  border-radius: 0;
  width: calc(100% - 1.2rem);
  z-index: 4;
}
.searchable-select .searchable-select__menu-list {
  max-height: 300px;
  padding: 0;
}

#MassDefectSelect-1.searchable-select .searchable-select__menu-list,
#DefectSelect-1.searchable-select .searchable-select__menu-list {
  max-height: calc(100vh / 2);
}

.searchable-select .searchable-select__option,
.searchable-select__menu-portal
  .searchable-select__menu
  .searchable-select__menu-list
  .searchable-select__option
  span {
  white-space: pre-line;
  overflow-wrap: break-word;
  cursor: pointer;
  color: var(--color-primary);
}

.searchable-select .searchable-select__option--is-selected,
.searchable-select__menu-portal
  .searchable-select__menu
  .searchable-select__menu-list
  .searchable-select__option--is-selected {
  background-color: transparent;
}

.searchable-select .searchable-select__option:hover,
.searchable-select .searchable-select__option--is-focused,
.searchable-select__menu-portal
  .searchable-select__menu
  .searchable-select__menu-list
  .searchable-select__option:hover,
.searchable-select__menu-portal
  .searchable-select__menu
  .searchable-select__menu-list
  .searchable-select__option--is-focused {
  background-color: var(--color-grey-light);
}

/* multu */
.searchable-select__option label {
  pointer-events: none;
}

.searchable-select .searchable-select__option label span {
  color: var(--color-primary);
  font-size: 16px;
}

.searchable-select .searchable-select__multi-value {
  background-color: transparent;
  min-width: auto;
}
.searchable-select .searchable-select__multi-value__label {
  background-color: var(--bg);
  font-size: 16px;
}

.searchable-select .searchable-select__multi-value__remove {
  width: 20px;
  height: 20px;
  background: var(--color-grey-light);
  margin: auto 0px;
  border-radius: 50%;
}

.searchable-select .searchable-select__value-container input {
  margin: 0;
}

.searchable-select .searchable-select__value-container input[inputmode='none'] {
  margin: 0;
  padding: 0;
  height: 1px;
}

/* defect cases */
.select-defect-wrapper .searchable-select {
  width: 300px;
}

.select-defect-wrapper .searchable-select__menu {
  z-index: 10;
  width: 100%;
  text-align: left;
}

.select-defect-wrapper .searchable-select__menu-list {
  max-height: 200px;
}

.select-defect-wrapper .searchable-select__value-container {
  border-bottom: none;
  margin: 0;
  padding: 0;
}

.select-defect-wrapper .searchable-select__input-container {
  height: 35px;
}

.select-defect-wrapper .searchable-select .searchable-select__control:focus,
.select-defect-wrapper
  .searchable-select
  .searchable-select__control--is-focused,
.select-defect-wrapper
  .searchable-select
  .searchable-select__control--menu-is-open {
  border: none;
  box-shadow: var(--box-shadow);
}
.select-defect-wrapper
  .searchable-select
  .searchable-select__control--is-focused:hover,
.select-defect-wrapper
  .searchable-select
  .searchable-select__control--menu-is-open:hover,
.select-defect-wrapper .searchable-select__control:hover {
  box-shadow: var(--box-shadow-hover);
}

.select-defect-wrapper .searchable-select__loading-indicator,
.select-defect-wrapper .searchable-select__dropdown-indicator {
  display: none;
}
.select-defect-wrapper .searchable-select__control {
  width: 100%;
  padding: 0 12px;
}

.select-defect-wrapper .searchable-select__single-value {
  margin: 0;
  height: inherit;
  font-weight: var(--font-weight-b);
  color: var(--color-primary-white);
}

/* custom-dropdown */
.custom-dropdown .searchable-select__control {
  background-color: var(--color-primary-white);
}

.custom-dropdown .searchable-select__menu {
  margin-bottom: 0;
}

.custom-dropdown .menu-top {
  inset: 200px auto 0 0;
}
