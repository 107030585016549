.main-container {
  padding-top: 64px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0;

  min-height: calc(100vh - 35px);
  max-width: 1920px;

  margin-left: auto;
  margin-right: auto;
}
