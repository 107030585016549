.login-main {
  display: flex;

  margin-bottom: 0;
}

.login-form__column {
  margin-top: 25vh;
}

.login-form__heading {
  font-size: 2rem;
  text-align: center;
}

.login-bg {
  height: calc(100vh - 35px);
  background-image: url('../../assets/login_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--color-primary);
}

.login-main__heading {
  font-size: 5rem;
  font-weight: 700;

  margin-bottom: 3rem;
}

.login-main__text {
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.2;

  max-width: 510px;
}

.login-bg.col {
  padding-top: 22vh;
  padding-left: 5%;
}
