.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  padding: 0.5rem 0.75rem;
  margin-top: auto;
  z-index: 4;
  font-size: 13px;
}

.footer.moved {
  justify-content: flex-start;
  padding-left: 435px;
}

.footer_item {
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  gap: 5px;
}

.footer_item a {
  color: var(--color-primary);
  transition: all 250ms ease;
}

.footer_item i {
  font-size: 20px;
  color: var(--color-primary);
}

.footer_item i[role='button'] {
  cursor: pointer;
}

@media only screen and (min-width: 1970px) {
  .footer.moved {
    justify-content: center;
    padding: 0.5rem 0.75rem;
  }
}
