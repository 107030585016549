.custom-badge {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  font-weight: var(--font-weight-mb);
  background: var(--color-red);
  color: var(--color-primary-white);
  top: 8px;
  right: 0px;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}
