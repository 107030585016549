.plate__holder {
  margin-right: auto;
  text-align: center;
  font-weight: 500;
}

.plate__wrapper {
  margin-bottom: 0;
  padding-top: 50px;
  width: fit-content;
  text-align: center;
}

.plate__wrapper h3,
.plate__wrapper h4 {
  margin-bottom: 3rem;
  font-weight: 700;
}

.plate__wrapper h5 {
  margin-bottom: 2rem;
}

.plate__wrapper h4 {
  color: var(--color-border-grey);
}

.plate__wrapper h4 span {
  color: var(--color-primary);
}
